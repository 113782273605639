import React, { useMemo } from 'react';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { UserIcon } from '@/assets/icons/UserIcon';
import Logo from '@/assets/images/LaverCup/sidebar-logo.png';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import LangSwitcher from '@/elements/LangSwitcher';

import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { triggerGTAGEvent } from '@/utils/analyticTracks';
import { enabledLanguages } from '@/utils/i18n';

export const Sidebar: React.FC = () => {
  const { isSidebarOpen, onSidebarClose } = useSidebarContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { value: jwt, removeItem: removeJWT } = useLocalStorage(
    StorageKeysEnum.jwt,
  );
  const { removeItem: removeUserID } = useLocalStorage(StorageKeysEnum.user_id);

  const goToSignup = () => {
    triggerGTAGEvent('signup.step1');
    navigate(RoutesEnum.REGISTRATION);
  };

  const goToLogin = () => {
    navigate(RoutesEnum.LOGIN);
  };

  const goToHome = () => {
    onSidebarClose();
    navigate(RoutesEnum.HOME);
  };

  const handleLogout = () => {
    removeJWT();
    removeUserID();
  };

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  return (
    <Drawer isOpen={isSidebarOpen} onClose={onSidebarClose} placement='left'>
      <DrawerOverlay />
      <DrawerContent backgroundColor={theme.colors.sidebar.bg}>
        <DrawerCloseButton color={theme.colors.sidebar.close} />
        <DrawerHeader pb='50px' pt='80px'>
          <Button onClick={goToHome} variant='ghost'>
            <Image alt='' src={Logo} />
          </Button>
        </DrawerHeader>

        <DrawerBody>
          {isLangSwitcherEnabled && (
            <Box maxW='100px' mb='20px' mr='auto' pt='20px' w='100%'>
              <LangSwitcher />
            </Box>
          )}
          {user && (
            <Button
              alignItems='center'
              color={theme.colors.sidebar.text}
              display='flex'
              fontSize='17px'
              fontWeight='700'
              justifyContent='flex-start'
              leftIcon={<UserIcon />}
              maxW='100%'
              onClick={() => navigate(RoutesEnum.PROFILE)}
              px='5px'
              variant='unstyled'
              w='fit-content'
            >
              <Text noOfLines={1}>{user.username}</Text>
            </Button>
          )}
        </DrawerBody>

        <DrawerFooter flexDirection='column'>
          <Flex align='center' justify='space-between' w='100%'>
            <>
              {jwt ? (
                <Button onClick={handleLogout} variant='secondary'>
                  Logout
                </Button>
              ) : (
                <>
                  <Button onClick={goToSignup} variant='primary'>
                    Join Now
                  </Button>
                  <Button onClick={goToLogin} variant='secondary'>
                    Login
                  </Button>
                </>
              )}
            </>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
