import React, { useCallback, useMemo, useState } from 'react';

import { Flex, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import Select, {
  ControlProps,
  components,
  StylesConfig,
  DropdownIndicatorProps,
} from 'react-select';

import ShimmerImage from '@/elements/ShimmerImage';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { countryFlagByName, enabledLanguages } from '@/utils/i18n';
import { getStorageItem } from '@/utils/localStorage';

type LanguageItemType = {
  value: string;
  label: string;
  image: string;
};

const LangSwitcherOption = ({
  innerProps,
  label,
  data,
}: {
  innerProps: any;
  label: string;
  data: LanguageItemType;
}) => (
  <Flex align='center' p='5px' {...innerProps}>
    <ShimmerImage
      alt={label}
      borderRadius='50%'
      h='auto'
      mr='5px'
      skeletonProps={{ width: '20px', borderRadius: '50%', mr: '5px' }}
      src={data.image}
      w='20px'
    />
    {label}
  </Flex>
);

const LangSwitcherControl = ({
  children,
  ...props
}: ControlProps<any, false>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { lang } = props.selectProps;

  return (
    <components.Control {...props}>
      <ShimmerImage
        borderRadius='50%'
        skeletonProps={{ borderRadius: '50%' }}
        src={lang}
      />
      {children}
    </components.Control>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<any, false>) => {
  const { selectProps } = props;
  let arrow;
  if (selectProps.menuIsOpen) {
    arrow = <BiChevronUp size='18px' />;
  } else {
    arrow = <BiChevronDown size='18px' />;
  }
  return (
    <components.DropdownIndicator {...props}>
      {arrow}
    </components.DropdownIndicator>
  );
};

const LangSwitcher: React.FC = () => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const { setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const languageOptions = useMemo<LanguageItemType[]>(
    () =>
      enabledLanguages.map((item: (typeof enabledLanguages)[number]) => ({
        value: item.toLowerCase(),
        label: item.toUpperCase(),
        image: countryFlagByName(item),
      })),
    [],
  );

  const defaultLanguage = useMemo(
    () =>
      languageOptions.find(
        (x) =>
          x.value ===
          getStorageItem<string>(StorageKeysEnum.i18nextLng).toLowerCase(),
      ) || languageOptions[0],
    [languageOptions],
  );

  const [currentLang, setCurrentLang] = useState(defaultLanguage);

  const onLangChange = useCallback(
    (opt: LanguageItemType) => {
      setCurrentLang(opt);
      i18n.changeLanguage(opt.value, () => setLang(opt.value));
    },
    [i18n, setLang],
  );

  const customStyles = useMemo<StylesConfig<any, false, any>>(
    () => ({
      control: (provided) => ({
        ...provided,
        background: theme.colors.langSwitcher.bg,
        borderRadius: '20px',
        border: '0',
        borderColor: theme.colors.langSwitcher.border,
        height: '30px',
        minHeight: '30px',
        padding: '3px',
        outline: '0',
        boxShadow: 'none',
        cursor: 'pointer',

        img: {
          width: '22px',
          height: '22px',
        },
      }),
      valueContainer: (props) => ({
        ...props,
        padding: '0 6px',

        div: {
          margin: '0',
        },
      }),
      indicatorsContainer: (props) => ({
        ...props,
        div: {
          padding: '0 3px 0 0',
        },
      }),
      singleValue: (props) => ({
        ...props,
        color: theme.colors.langSwitcher.text,
      }),
      option: (props) => ({
        ...props,
        cursor: 'pointer',
        color: theme.colors.langSwitcher.text,
      }),
      menuPortal: (provided) => ({
        ...provided,
        border: 'none',
        zIndex: 9999,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 1,
        top: '0',
        marginTop: '0px',
        border: 'none',
        boxShadow: 'none',
        color: theme.colors.langSwitcher.text,
        background: theme.colors.langSwitcher.bg,
        cursor: 'pointer',
      }),
      indicatorSeparator: () => ({ display: 'none' }),
    }),
    [
      theme.colors.langSwitcher.bg,
      theme.colors.langSwitcher.border,
      theme.colors.langSwitcher.text,
    ],
  );

  return null; // Temporally hide

  return (
    <Select
      components={{
        Option: LangSwitcherOption,
        Control: LangSwitcherControl,
        DropdownIndicator,
      }}
      defaultValue={currentLang}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      lang={currentLang.image}
      onChange={onLangChange}
      options={languageOptions}
      styles={customStyles}
      value={languageOptions.find((x) => x.value === currentLang.value)}
    />
  );
};

export default LangSwitcher;
